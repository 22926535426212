import { HttpHandler, http } from 'msw';
import { question } from './resolvers/question';
import { ranking } from './resolvers/ranking';
import { survey } from './resolvers/survey';

/**
 * MSWのイベントハンドラー定義
 *
 * NOTE:
 * API仕様書に記載のあるリクエストは Orval が MSW のイベントハンドラーを自動生成するが、
 * 上書きしたい場合はここで定義
 */
export const handlers: HttpHandler[] = [
  http.get('/ranking/top', ranking.get),
  http.get('/ranking', ranking.userId),
  http.post('/ranking', ranking.postUserScore),
  http.post('/ranking/name', ranking.postUserName),
  http.get('/question/number', question.getNumber),
  http.post('/question/answer', question.postAnswer),
  http.post('/survey/answer', survey.answer),
];
