import { faker } from '@faker-js/faker';
import { HttpResponse, delay, type ResponseResolver } from 'msw';

const limit: number = 10;
const myRank: number = 10;
const bottomRank: number = 10;

export const get: ResponseResolver = async () => {
  await delay('real');

  const statusCode = Number(
    new URLSearchParams(window.location.search).get('statusCode'),
  );

  let score = bottomRank;

  const mock = Array.from({ length: limit }, (_, i) => i + 1).map((i) => {
    score--;

    return {
      name: i === myRank ? '自分' : faker.word.sample(),
      rank: i,
      score,
    };
  });

  if (!statusCode) {
    return new HttpResponse(JSON.stringify(mock), { status: 200 });
  }
  return new HttpResponse(
    JSON.stringify({
      type: 'Error',
      path: '/path/to/api',
      message: 'Unauthorized',
    }),
    { status: statusCode },
  );
};

export const userId: ResponseResolver<{
  params: { userId: string };
}> = async ({ params }) => {
  await delay('real');

  const { userId: _userId } = params;

  const statusCode = Number(
    new URLSearchParams(window.location.search).get('statusCode'),
  );

  const mock = {
    upper:
      limit > 8
        ? {
            name: '前の人',
            rank: myRank - 1,
            score: myRank === bottomRank ? 1 : bottomRank - myRank + 1,
          }
        : undefined,
    user: {
      name: '自分',
      rank: myRank,
      score: myRank === bottomRank ? 0 : bottomRank - myRank,
    },
    lower:
      myRank !== bottomRank
        ? {
            name: '後の人',
            rank: myRank + 1,
            score: bottomRank - myRank - 1,
          }
        : undefined,
  };

  if (!statusCode) {
    return new HttpResponse(JSON.stringify(mock), { status: 200 });
  }
  return new HttpResponse(
    JSON.stringify({
      type: 'Error',
      path: '/path/to/api',
      message: 'Unauthorized',
    }),
    { status: statusCode },
  );
};
