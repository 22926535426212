import { faker } from '@faker-js/faker';
import { type ResponseResolver, delay, HttpResponse } from 'msw';

const statusCode = Number(
  new URLSearchParams(window.location.search).get('statusCode'),
);

export const getNumber: ResponseResolver = async () => {
  await delay('real');

  const mock = (): number => {
    return faker.number.int({ min: 1, max: 9999999 });
  };

  if (!statusCode) {
    return new HttpResponse(JSON.stringify(mock()), { status: 200 });
  }
  return new HttpResponse(
    JSON.stringify({
      type: 'Error',
      path: '/path/to/api',
      message: 'Unauthorized',
    }),
    { status: statusCode },
  );
};
