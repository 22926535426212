import { type ResponseResolver, delay, HttpResponse } from 'msw';
import type { PostQuestionAnswerBody } from '~/api/generated/model';

const statusCode = Number(
  new URLSearchParams(window.location.search).get('statusCode'),
);

export const postAnswer: ResponseResolver<
  Record<string, unknown>,
  PostQuestionAnswerBody
> = async ({ request }) => {
  await delay('real');

  const body = await request.json();

  const mock = (): PostQuestionAnswerBody => {
    return {
      ...body,
    };
  };

  if (!statusCode) {
    return new HttpResponse(JSON.stringify(mock()), { status: 200 });
  }
  return new HttpResponse(
    JSON.stringify({
      type: 'Error',
      path: '/path/to/api',
      message: 'Unauthorized',
    }),
    { status: statusCode },
  );
};
